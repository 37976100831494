import { render, staticRenderFns } from "./ImportCostUnloadingCostDetail.vue?vue&type=template&id=52c3f0bc&scoped=true&"
import script from "./ImportCostUnloadingCostDetail.vue?vue&type=script&lang=ts&"
export * from "./ImportCostUnloadingCostDetail.vue?vue&type=script&lang=ts&"
import style0 from "./ImportCostUnloadingCostDetail.vue?vue&type=style&index=0&id=52c3f0bc&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52c3f0bc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/workspace/GPW/gpw-erp-fe-staging/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('52c3f0bc')) {
      api.createRecord('52c3f0bc', component.options)
    } else {
      api.reload('52c3f0bc', component.options)
    }
    module.hot.accept("./ImportCostUnloadingCostDetail.vue?vue&type=template&id=52c3f0bc&scoped=true&", function () {
      api.rerender('52c3f0bc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/logistic/good-receipt/import-cost/components/ImportCostUnloadingCostDetail.vue"
export default component.exports