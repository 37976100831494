var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.toggle.unloadingCostDetail
    ? _c(
        "div",
        { staticClass: "px-3 border-bottom" },
        [
          _c("div", [
            _c(
              "div",
              {
                staticClass: "d-flex align-items-center justify-content-start"
              },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "form-label ant-col-5 ant-col ant-form-item-label ant-form-item-label-left"
                  },
                  [_vm._v(_vm._s(_vm.$t("lbl_handling_fee")) + ":")]
                ),
                _c(
                  "a-form-model-item",
                  { staticClass: "mr-3", attrs: { prop: "grossWeight" } },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        value: _vm.form.grossWeight,
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: ""
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { staticClass: "mr-3", attrs: { prop: "handlingFeeValue" } },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        value: _vm.form.handlingFeeValue,
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: _vm.isUnbilled
                      },
                      on: {
                        change: function(e) {
                          return _vm.commitState(e, "handlingFeeValue")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    attrs: {
                      prop: "handlingFeeTotal",
                      colon: false,
                      "label-col": _vm.labelCol
                    }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))]),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.form.handlingFeeTotal))
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _c("div", [
            _c(
              "div",
              {
                staticClass: "d-flex align-items-center justify-content-start"
              },
              [
                _c(
                  "label",
                  {
                    staticClass:
                      "form-label ant-col-5 ant-col ant-form-item-label ant-form-item-label-left"
                  },
                  [_vm._v(_vm._s(_vm.$t("lbl_tax")) + ":")]
                ),
                _c(
                  "a-form-model-item",
                  { staticClass: "mr-3", attrs: { prop: "handlingFeeTotal" } },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        value: _vm.form.handlingFeeTotal,
                        formatter: _vm.formatterNumber,
                        parser: _vm.reverseFormatNumber,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: ""
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticClass: "mr-3",
                    attrs: { prop: "taxUnloadingPercent" }
                  },
                  [
                    _c("a-input-number", {
                      staticStyle: { width: "170px" },
                      attrs: {
                        value: _vm.form.taxUnloadingPercent,
                        formatter: _vm.formatterPercent,
                        precision: _vm.DECIMAL_PLACES_QTY,
                        min: 0,
                        max: 100,
                        placeholder: _vm.$t("lbl_type_here"),
                        readonly: _vm.isUnbilled
                      },
                      on: {
                        change: function(e) {
                          return _vm.commitState(e, "taxUnloadingPercent")
                        }
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  {
                    staticClass: "mr-3",
                    attrs: {
                      prop: "taxUnloadingTotal",
                      colon: false,
                      "label-col": _vm.labelCol
                    }
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))]),
                    _c("span", { staticClass: "ml-3" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.form.taxUnloadingTotal))
                      )
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _c(
            "a-form-model-item",
            {
              attrs: {
                prop: "stampDutyUnloading",
                "label-col": _vm.labelCol,
                label: _vm.$t("lbl_stamp_duty")
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.stampDutyUnloading,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "stampDutyUnloading")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                prop: "otherUnloading",
                "label-col": _vm.labelCol,
                label: _vm.$t("lbl_others")
              }
            },
            [
              _c("span", { staticClass: "mr-2" }, [
                _vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))
              ]),
              _c("a-input-number", {
                staticStyle: { width: "170px" },
                attrs: {
                  value: _vm.form.otherUnloading,
                  formatter: _vm.formatterNumber,
                  parser: _vm.reverseFormatNumber,
                  precision: _vm.DECIMAL_PLACES_QTY,
                  min: 0,
                  placeholder: _vm.$t("lbl_type_here"),
                  readonly: _vm.isUnbilled
                },
                on: {
                  change: function(e) {
                    return _vm.commitState(e, "otherUnloading")
                  }
                }
              })
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                prop: "totalUnloadingCost",
                "label-col": _vm.labelCol,
                label: _vm.$t("lbl_total_unloading_cost")
              }
            },
            [
              _c("span", [_vm._v(_vm._s(_vm.CURRENCY_CODE.IDR))]),
              _c("span", { staticClass: "ml-3" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.form.totalUnloadingCost)))
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }